import Dashboard from './Components/Dashboard/Dashboard'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './Components/Home'
import Main from './Components/Main/Main'
import Distributions from './Components/Distributions/Distributions'
import Billing from './Components/Billing/Billing'
import Documentation from './Components/Documentation/Documentation'
import ContactUs from './Components/ContactUs/ContactUs'
import ChangePass from './Components/ChangePass/ChangePass'
import CreateDist from './Components/CreateDist/CreateDist'
import BillingInfo from './Components/BillingInfo/BillingInfo'
import BillingAdvanced from './Components/BillingAdvanced/BillingAdvanced'
import BillingInvoices from './Components/BillingInvoices/BillingInvoices'
import DeleteAccount from './Components/DeleteAccount/DeleteAccount'
import Distribution from './Components/Distribution/Distribution'
import DistOverview from './Components/DistOverview/DistOverview'
import DistOrigins from './Components/DistOrigins/DistOrigins'
import DistCache from './Components/DistCache/DistCache'
import DistHttps from './Components/DistHttps/DistHttps'
import DistMonitoring from './Components/DistMonitoring/DistMonitoring'
import DistAdvanced from './Components/DistAdvanced/DistAdvanced'
import MainContentDist from './Components/MainContent/MainContentDist'
import CreateOrigin from './Components/CreateOrigin/CreateOrigin'
import EditOrigin from './Components/EditOrigin/EditOrigin'
import CreateCache from './Components/CreateCache/CreateCache'
import EditCache from './Components/EditCache/EditCache'
import CreateCert from './Components/CreateCert/CreateCert'
import InvalidateAdvanced from './Components/InvalidateAdvanced/InvalidateAdvanced'
import DeleteAdvanced from './Components/DeleteAdvanced/DeleteAdvanced'
import Layout from './Components/Layout/Layout'
import { authSignOut } from './client'
import SubscriptionSuccess from './Components/SubscriptionSuccess/SubscriptionSuccess'
import { SubscriptionCheckoutRedirect } from './Components/SubscriptionInfo/SubscriptionCheckoutLink'
import { SubscriptionPortalRedirect } from './Components/SubscriptionInfo/SubscriptionPortalLink'
import AccessKeys from './Components/AccessKeys/AccessKeys'
import StorageList from './Components/Storage/StorageList'
import Storage from './Components/Storage/Storage'
import MainContentStorage from './Components/Storage/MainContentStorage'
import StorageCreate from './Components/Storage/StorageCreate'
import StorageOverview from './Components/Storage/StorageOverview'
import StorageMonitoring from './Components/Storage/StorageMonitoring'
import StorageRemoteAccess from './Components/Storage/StorageRemoteAccess'
import StorageAdvanced from './Components/Storage/StorageAdvanced'
import StorageAdvancedDelete from './Components/Storage/StorageAdvancedDelete'
import SubscriptionDetails from './Components/SubscriptionDetails/SubscriptionDetails'

const Logout = async () => await authSignOut(true)
// const Redirect = async urlHandler => (window.location.href = await urlHandler())

function App() {
  return (
    <Router basename={process.env.REACT_APP_PROXY_PATH} >
      <div className="flashedge-app">
        <Routes>
          <Route element={<Layout />}>
            <Route element={<Home />}>
              <Route
                path="/subscription-success"
                element={<SubscriptionSuccess />}
              />
              <Route
                path="/subscription-checkout"
                element={<SubscriptionCheckoutRedirect />}
              />
              <Route
                path="/billing-portal"
                element={<SubscriptionPortalRedirect />}
              />
              <Route element={<Dashboard />}>
                <Route element={<Main />}>
                  <Route path="/" element={<Distributions />} />
                  <Route path="/logout" element={<Logout />} />
                  <Route
                    path="/distributions"
                    element={<Distributions />}
                  ></Route>
                  <Route path="/change-password" element={<ChangePass />} />
                  <Route path="/delete-account" element={<DeleteAccount />} />
                  <Route
                    path="/distributions/create"
                    element={<CreateDist />}
                  />
                  <Route element={<Distribution />}>
                    <Route element={<MainContentDist />}>
                      <Route
                        path="/distributions/:distributionId"
                        element={<DistOverview />}
                      />
                      <Route
                        path="/distributions/:distributionId/origins"
                        element={<DistOrigins />}
                      />
                      <Route
                        path="/distributions/:distributionId/origins/create"
                        element={<CreateOrigin />}
                      />
                      <Route
                        path="/distributions/:distributionId/origins/:originId/edit"
                        element={<EditOrigin />}
                      />
                      <Route
                        path="/distributions/:distributionId/cache"
                        element={<DistCache />}
                      />
                      <Route
                        path="/distributions/:distributionId/cache/create"
                        element={<CreateCache />}
                      />
                      <Route
                        path="/distributions/:distributionId/cache/:cacheId/edit"
                        element={<EditCache />}
                      />
                      <Route
                        path="/distributions/:distributionId/https"
                        element={<DistHttps />}
                      />
                      <Route
                        path="/distributions/:distributionId/https/create"
                        element={<CreateCert />}
                      />
                      <Route
                        path="/distributions/:distributionId/monitoring"
                        element={<DistMonitoring />}
                      />
                      <Route
                        path="/distributions/:distributionId/advanced"
                        element={<DistAdvanced />}
                      />
                      <Route
                        path="/distributions/:distributionId/advanced/invalidate"
                        element={<InvalidateAdvanced />}
                      />
                      <Route
                        path="/distributions/:distributionId/advanced/delete"
                        element={<DeleteAdvanced />}
                      />
                    </Route>
                  </Route>
                  <Route path="/storage" element={<StorageList />}></Route>
                  <Route path="/storage/create" element={<StorageCreate />} />
                  <Route element={<Storage />}>
                    <Route element={<MainContentStorage />}>
                      <Route
                        path="/storage/:storageId"
                        element={<StorageOverview />}
                      />
                      <Route
                        path="/storage/:storageId/monitoring"
                        element={<StorageMonitoring />}
                      />
                      <Route
                        path="/storage/:storageId/remote-access"
                        element={<StorageRemoteAccess />}
                      />
                      <Route
                        path="/storage/:storageId/advanced"
                        element={<StorageAdvanced />}
                      />
                      <Route
                        path="/storage/:storageId/advanced/delete"
                        element={<StorageAdvancedDelete />}
                      />
                    </Route>
                  </Route>
                </Route>
                <Route element={<Billing />}>
                  <Route path="/billing" element={<SubscriptionDetails />} />
                  <Route path="/billing/info" element={<BillingInfo />} />
                  <Route
                    path="/billing/invoices"
                    element={<BillingInvoices />}
                  />
                  <Route path="/billing/access-keys" element={<AccessKeys />} />
                  <Route
                    path="/billing/advanced"
                    element={<BillingAdvanced />}
                  />
                </Route>

                <Route path="/documentation" element={<Documentation />} />
                <Route path="/contact-us" element={<ContactUs />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </div>
    </Router>
  )
}

export default App
