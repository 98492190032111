import React, { useEffect, useState } from 'react'
import { useStateContext } from '../../Context/ContextProvider'
import PillInput from '../PillInput/PillInput'
import { useForm, Controller } from 'react-hook-form'
import { prepareCacheFormToSubmit } from '../../Actions/cache'
import { useMutation } from 'urql'
import { buildMutationQuery } from '../../client'
import { Link, useNavigate } from 'react-router-dom'
import { SubscriptionGuard } from '../SubscriptionGuard/SubscriptionGuard'

const CreateCache = () => {
  const { currentDist, setReloadCurrentDist, activeDist } = useStateContext()
  const navigate = useNavigate()

  const origins = currentDist.origins

  const [selectedItem, setSelectedItem] = useState('')
  const [selectActive, setSelectActive] = useState(false)
  /*const [ttl, setTime] = useState({
    days: '0',
    hours: '0',
    minutes: '0',
    seconds: '0',
  })
  const [form, setForm] = useState({
    origins: 'Origin 1',
    pathName: '',
    cache: 'best-static',
    compression: 'compression-enabled',
    https: 'http-https',
    ttl: {},
    method: 'get-head',
    headerFwd: 'header-default',
    headerPill: [],
    cookieFwd: 'cookie-dont',
    cookiePill: [],
    qsFwd: 'qs-dont',
    qsPill: [],
  })*/

  const [cacheCreateResult, cacheCreate] = useMutation(
    buildMutationQuery('cacheCreate', ['success', { cache: ['id'] }])
  )

  const {
    watch,
    register,
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm({
    // defaultValues: cache,
  })

  const watchPolicy = watch('policy')
  const watchHeaderForwardingType = watch('headerForwarding.type')
  const watchCookieForwardingType = watch('cookieForwarding.type')
  const watchQueryStringForwardingType = watch('queryStringForwarding.type')

  const checkKeyDown = e => {
    if (e.key === 'Enter') e.preventDefault()
  }

  const onSubmit = async formData => {
    const input = prepareCacheFormToSubmit(formData)

    const result = await cacheCreate({
      input: {
        distributionId: currentDist.id,
        ...input,
      },
    })

    if (result?.data?.cacheCreate?.success) {
      setReloadCurrentDist(true)
      navigate(
        `/distributions/${activeDist}/cache/${result.data.cacheCreate.cache.id}/edit`
      )
    }
  }

  useEffect(() => {
    setValue('originId', origins[0].id)
    setSelectedItem(origins[0])
  }, [origins, setValue])

  const handleSelectClick = () => {
    setSelectActive(!selectActive)
  }

  const handleClick = origin => {
    setValue('originId', origin.id)
    setSelectedItem(origin)
    setSelectActive(!selectActive)
  }
  /*
  useEffect(() => {
    const name = Object.keys(cachePills)
    const value = Object.values(cachePills)
    if (name.length >= 1) {
      setForm(state => ({
        ...state,
        [name]: value,
      }))
    }
  }, [cachePills])*/

  return (
    <form
      className="dashboard__main-form"
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={checkKeyDown}
    >
      <SubscriptionGuard type="form">
        <h2 className="content-title">Cache Behaviour</h2>
        <h3 className="content-subtitle">Select Origin</h3>
        <div className="input-wrap select-input">
          <div
            className={`input--select-custom ${selectActive ? 'active' : ''}`}
          >
            <span className="input--select-show" onClick={handleSelectClick}>
              {selectedItem.name}
            </span>
            <div className="input--select-list">
              {origins.map((origin, index) => {
                return (
                  <span
                    className="input--select-item"
                    key={index}
                    onClick={() => handleClick(origin)}
                  >
                    {origin.name}
                  </span>
                )
              })}
            </div>
          </div>
        </div>
        <div className="dashboard__separator"></div>
        <h3 className="content-subtitle">Path or File Type Selection</h3>
        <p className="content-text">
          Specify a path or file to a directory or file. Use an asterisk symbol
          to specify wildcard directories (e.g. /path/to/assets/*) or file type
          (e.g. /*.html, /*jpg, /*js). Directory and file path are
          case-sensitive.
        </p>
        <div className="input-wrap">
          <label className="input-label">
            Path (/folder/images/*) or file type (/*.html, /*jpg...):
          </label>
          <input
            className="input"
            type="text"
            id="path-name"
            placeholder="Add Path or File Type"
            required
            {...register('path', { required: true })}
          ></input>
        </div>
        <div className="dashboard__separator"></div>
        <h3 className="content-subtitle">Object compression</h3>
        <p className="content-text">
          Your distribution can automatically compress certain types of objects
          (files) and serve the compressed objects when viewers support them.
        </p>
        <div className="input-wrap radio-btn">
          <input
            className="input"
            id="compression-enabled"
            name="compression"
            type="radio"
            value="1"
            required
            {...register('compression', { required: true })}
          ></input>
          <label className="input-label" htmlFor="compression-enabled">
            <span className="bold">Compression enabled</span>
          </label>
        </div>
        <div className="input-wrap radio-btn">
          <input
            className="input"
            id="compression-disabled"
            name="compression"
            type="radio"
            value="0"
            required
            {...register('compression', { required: true })}
          ></input>
          <label className="input-label" htmlFor="compression-disabled">
            <span className="bold">Compression disabled</span>
          </label>
        </div>
        <div className="dashboard__separator"></div>
        <h3 className="content-subtitle">Redirect HTTP/HTTPS</h3>
        <p className="content-text">
          If you want to ensure that the objects that viewers get from FlashEdge
          were encrypted when FlashEdge got them from your origin, use Redirect
          HTTP to HTTPS or HTTPS only. Make sure that your origin is accessible
          on the right ports.
        </p>
        <div className="input-wrap radio-btn">
          <input
            className="input"
            id="https-radio-1"
            name="https"
            type="radio"
            value="NONE"
            required
            {...register('httpRedirection', { required: true })}
          ></input>
          <label className="input-label" htmlFor="https-radio-1">
            <span className="bold">HTTP and HTTPS</span>
          </label>
        </div>
        <div className="input-wrap radio-btn">
          <input
            className="input"
            id="https-radio-2"
            name="https"
            type="radio"
            value="HTTP_TO_HTTPS"
            required
            {...register('httpRedirection', { required: true })}
          ></input>
          <label className="input-label" htmlFor="https-radio-2">
            <span className="bold">Redirect HTTP to HTTPS</span>
          </label>
        </div>
        <div className="input-wrap radio-btn">
          <input
            className="input"
            id="https-radio-3"
            name="https"
            type="radio"
            value="HTTPS_ONLY"
            required
            {...register('httpRedirection', { required: true })}
          ></input>
          <label className="input-label" htmlFor="https-radio-3">
            <span className="bold">HTTPS only</span>
          </label>
        </div>
        <div className="dashboard__separator"></div>
        <h3 className="content-subtitle">Cache policy</h3>
        <p className="content-text">
          Your distribution can be configured to cache content from your origin
          differently. Choose a pre-configured behavior:
        </p>
        <div className="input-wrap radio-btn">
          <input
            className="input"
            id="best-static"
            name="cache"
            type="radio"
            value="EVERYTHING"
            required
            {...register('policy', { required: true })}
          ></input>
          <label className="input-label" htmlFor="best-static">
            <span className="bold-selected">
              Cache everything (Best for static content):
            </span>
            <br />
            Your FlashEdge distribution will cache and serve your entire website
            as static content. Choose this option if your website has content
            that doesn't change depending on who views it, or if your website
            does not use cookies, headers, or query strings to personalize
            content.
          </label>
        </div>
        <div className="input-wrap radio-btn">
          <input
            className="input"
            id="best-dynamic"
            name="cache"
            type="radio"
            value="NOTHING"
            required
            {...register('policy', { required: true })}
          ></input>
          <label className="input-label" htmlFor="best-dynamic">
            <span className="bold-selected">
              Cache nothing (Best for dynamic content):
            </span>
            <br />
            Your distribution will not cache any content. Choose this option if
            your website or application uses cookies, headers, and query strings
            to personalize content for individual users.
          </label>
        </div>
        <div className="input-wrap radio-btn">
          <input
            className="input"
            id="best-custom"
            name="cache"
            type="radio"
            value="CUSTOM"
            required
            {...register('policy', { required: true })}
          ></input>
          <label className="input-label" htmlFor="best-custom">
            <span className="bold-selected">
              Custom cache (Advanced cache settings)
            </span>
            <br />
          </label>
        </div>
        {watchPolicy === 'CUSTOM' && (
          <>
            <div className="dashboard__separator"></div>
            <h2 className="content-title-in">Advanced cache settings</h2>
            <h3 className="content-subtitle">
              Time-to-live (TTL) cache policy
            </h3>
            <p className="content-text">
              This specifies the amount of time objects stay in the
              distribution's cache.
            </p>
            <div className="time-to-live">
              Min TTL
              <div className="time-to__box">
                <input
                  className="time-to__input"
                  type="number"
                  min="0"
                  max="31536000"
                  defaultValue={0}
                  required
                  {...register('objectCaching.minTTL', { required: true })}
                ></input>
              </div>
              <div className="time-to__box">
                Default TTL
                <input
                  className="time-to__input"
                  type="number"
                  min="0"
                  max="31536000"
                  defaultValue={86400}
                  required
                  {...register('objectCaching.maxTTL', { required: true })}
                ></input>
              </div>
              <div className="time-to__box">
                Max TTL
                <input
                  className="time-to__input"
                  type="number"
                  min="0"
                  max="31536000"
                  defaultValue={31536000}
                  required
                  {...register('objectCaching.defaultTTL', { required: true })}
                ></input>
              </div>
            </div>
            <div className="dashboard__separator"></div>
            <h3 className="content-subtitle">Allowed HTTP methods</h3>
            <p className="content-text">
              Choose the HTTP methods that are processed and forwarded to your
              origin by your distribution.
            </p>
            <div className="input-wrap radio-btn">
              <input
                className="input"
                id="method-radio-1"
                name="method"
                type="radio"
                value="GET_HEAD"
                required
                {...register('httpMethods', { required: true })}
              ></input>
              <label className="input-label" htmlFor="method-radio-1">
                <span className="bold">GET, HEAD</span>
              </label>
            </div>
            <div className="input-wrap radio-btn">
              <input
                className="input"
                id="method-radio-2"
                name="method"
                type="radio"
                value="GET_HEAD_OPTIONS"
                {...register('httpMethods', { required: true })}
              ></input>
              <label className="input-label" htmlFor="method-radio-2">
                <span className="bold">GET, HEAD, OPTIONS</span>
              </label>
            </div>
            <div className="input-wrap radio-btn">
              <input
                className="input"
                id="method-radio-3"
                name="method"
                type="radio"
                value="ALL"
                {...register('httpMethods', { required: true })}
              ></input>
              <label className="input-label" htmlFor="method-radio-3">
                <span className="bold">
                  GET, HEAD, OPTIONS, PUT, POST, PATCH, DELETE
                </span>
              </label>
            </div>
            <div className="dashboard__separator"></div>
            <h3 className="content-subtitle">Header forwarding</h3>
            <p className="content-text">
              Choose the headers that are forwarded to your origin by your
              distribution. The response from your origin is cached by your
              distribution only for the specified headers.
            </p>
            <div className="input-wrap radio-btn">
              <input
                className="input"
                id="header-fwd-radio-1"
                name="headerFwd"
                type="radio"
                value="DEFAULT"
                required
                {...register('headerForwarding.type', { required: true })}
              ></input>
              <label className="input-label" htmlFor="header-fwd-radio-1">
                <span className="bold">Forward default headers</span>
              </label>
            </div>
            <div className="input-wrap radio-btn">
              <input
                className="input"
                id="header-fwd-radio-2"
                name="headerFwd"
                type="radio"
                value="ALL"
                required
                {...register('headerForwarding.type', { required: true })}
              ></input>
              <label className="input-label" htmlFor="header-fwd-radio-2">
                <span className="bold">Forward all headers</span>
              </label>
            </div>
            <div className="input-wrap radio-btn">
              <input
                className="input"
                id="header-fwd-radio-3"
                name="headerFwd"
                type="radio"
                value="CUSTOM"
                required
                {...register('headerForwarding.type', { required: true })}
              ></input>
              <label className="input-label" htmlFor="header-fwd-radio-3">
                <span className="bold">Forward headers I specify</span>
              </label>
            </div>
            {watchHeaderForwardingType === 'CUSTOM' && (
              <div className="input-wrap">
                <Controller
                  control={control}
                  name="headerForwarding.values"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <PillInput
                      name="headerForwarding.values"
                      id="headerForwardingValues"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </div>
            )}
            <div className="dashboard__separator"></div>
            <h3 className="content-subtitle">Cookie forwarding</h3>
            <p className="content-text">
              Choose the cookies that are forwarded to your origin by your
              distribution. The response from your origin is cached by your
              distribution only for the specified cookies.
            </p>
            <div className="input-wrap radio-btn">
              <input
                className="input"
                id="cookie-fwd-radio-1"
                name="cookieFwd"
                type="radio"
                value="NONE"
                required
                {...register('cookieForwarding.type', { required: true })}
              ></input>
              <label className="input-label" htmlFor="cookie-fwd-radio-1">
                <span className="bold">Don't forward cookies</span>
              </label>
            </div>
            <div className="input-wrap radio-btn">
              <input
                className="input"
                id="cookie-fwd-radio-2"
                name="cookieFwd"
                type="radio"
                value="ALL"
                required
                {...register('cookieForwarding.type', { required: true })}
              ></input>
              <label className="input-label" htmlFor="cookie-fwd-radio-2">
                <span className="bold">Forward all cookies</span>
              </label>
            </div>
            <div className="input-wrap radio-btn">
              <input
                className="input"
                id="cookie-fwd-radio-3"
                name="cookieFwd"
                type="radio"
                value="CUSTOM"
                required
                {...register('cookieForwarding.type', { required: true })}
              ></input>
              <label className="input-label" htmlFor="cookie-fwd-radio-3">
                <span className="bold">Forward cookies I specify</span>
              </label>
            </div>
            {watchCookieForwardingType === 'CUSTOM' && (
              <div className="input-wrap">
                <Controller
                  control={control}
                  name="cookieForwarding.values"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <PillInput
                      name="cookieForwarding.values"
                      id="cookieForwardingValues"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </div>
            )}
            <div className="dashboard__separator"></div>
            <h3 className="content-subtitle">Query string forwarding</h3>
            <p className="content-text">
              Choose to forward query strings to your origin. You can cache your
              content based on specified query string parameters. If none are
              specified, your content will be cached based on all query string
              parameters.
            </p>
            <div className="input-wrap radio-btn">
              <input
                className="input"
                id="qs-fwd-radio-1"
                name="qsFwd"
                type="radio"
                value="NONE"
                required
                {...register('queryStringForwarding.type', { required: true })}
              ></input>
              <label className="input-label" htmlFor="qs-fwd-radio-1">
                <span className="bold">Don't forward query strings</span>
              </label>
            </div>
            <div className="input-wrap radio-btn">
              <input
                className="input"
                id="qs-fwd-radio-2"
                name="qsFwd"
                type="radio"
                value="ALL"
                required
                {...register('queryStringForwarding.type', { required: true })}
              ></input>
              <label className="input-label" htmlFor="qs-fwd-radio-2">
                <span className="bold">Forward all query strings</span>
              </label>
            </div>
            <div className="input-wrap radio-btn">
              <input
                className="input"
                id="qs-fwd-radio-3"
                name="qsFwd"
                type="radio"
                value="CUSTOM"
                required
                {...register('queryStringForwarding.type', { required: true })}
              ></input>
              <label className="input-label" htmlFor="qs-fwd-radio-3">
                <span className="bold">Forward query strings I specify</span>
              </label>
            </div>
            {watchQueryStringForwardingType === 'CUSTOM' && (
              <div className="input-wrap">
                <Controller
                  control={control}
                  name="queryStringForwarding.values"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <PillInput
                      name="queryStringForwarding.values"
                      id="queryStringForwardingValues"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </div>
            )}
          </>
        )}
        <div className="dashboard__separator"></div>
        {cacheCreateResult.error && (
          <p className={`content-text`}>
            <span className="warning">
              {cacheCreateResult.error.message.replace('[GraphQL] ', '')}
            </span>
          </p>
        )}
        <div className="button-wrapper">
          <button
            className="theme-btn form-btn"
            type="submit"
            disabled={!isDirty || !isValid || isSubmitting}
          >
            {isSubmitting && (
              <svg
                className="spinner"
                viewBox="0 0 50 50"
                width="24"
                height="24"
              >
                <circle
                  className="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            )}
            Create New Behaviour
          </button>
          <Link to={`/distributions/${activeDist}/cache/`}>
            <button className="theme-btn ml-auto-20" type="button">
              Go Back
            </button>
          </Link>
        </div>
      </SubscriptionGuard>
    </form>
  )
}

export default CreateCache
