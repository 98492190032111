import { useContext, createContext } from 'react'
import { useEffect, useState } from 'react'
import { authConfigure, authSession, initGraphQLClient } from '../client'
import { config } from '../config'
import { Provider as GraphQLProvider } from 'urql'

authConfigure(config)

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [isAuthenticated, userHasAuthenticated] = useState(false)
  const [session, setSession] = useState(null)
  const [identity, setIdentity] = useState({})
  const [token, setToken] = useState(null)

  useEffect(() => {
    onLoad()
  }, [])

  async function onLoad() {
    try {
      const session = await authSession(true)
      const idToken = session.getIdToken
        ? session.getIdToken()
        : session.idToken
      const jwtToken = idToken.getJwtToken
        ? idToken.getJwtToken()
        : idToken.jwtToken
      setSession(session)
      setIdentity(idToken.payload)
      setToken(jwtToken)
      userHasAuthenticated(true)
    } catch (e) {
      if (e !== 'No current user') {
        console.log(e)
      } else {
        throw e
      }
    }

    setIsAuthenticating(false)
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userHasAuthenticated,
        session,
        identity,
        token,
      }}
    >
      {!isAuthenticating && (
        <GraphQLProvider
          value={initGraphQLClient({
            graphqlEndpoint: config.API.graphql_endpoint,
            subscriptionEndpoint: config.API.subscription_endpoint,
            token,
          })}
        >
          {children}
        </GraphQLProvider>
      )}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)
