import React from 'react'
import './Sidebar.scss'
import plusIcon from '../../Assets/Images/plus.svg'
import { useStateContext } from '../../Context/ContextProvider'
import { Link, NavLink } from 'react-router-dom'
import { SubscriptionGuard } from '../SubscriptionGuard/SubscriptionGuard'

const StorageSidebar = () => {
  const { storageList } = useStateContext()

  return (
    <>
      <div className="dashboard__sidebar-nav">
        <ul className="dashboard__sidebar-nav-list">
          <li className="dashboard__sidebar-nav-list-item">
            <NavLink
              to="/storage"
              className={({ isActive, isPending }) =>
                'dashboard__sidebar-nav-list-item-link ' +
                (isPending ? 'pending' : isActive ? 'active' : '')
              }
            >
              <svg
                className="dashboard__sidebar-nav-list-item-icon globe"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="#505050"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2 12H22"
                  stroke="#505050"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2V2Z"
                  stroke="#505050"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="dashboard__sidebar-nav-list-item-text">
                Storage
              </span>
            </NavLink>
          </li>
        </ul>
        <ul className={`dashboard__sidebar-sublist`}>
          {storageList.map(item => {
            return (
              <li key={item.id} className="dashboard__sidebar-sublist-item">
                <NavLink
                  to={`/storage/${item.id}`}
                  className={({ isActive, isPending }) =>
                    'dashboard__sidebar-sublist-item-link ' +
                    (isPending ? 'pending' : isActive ? 'active' : '')
                  }
                >
                  <p
                    className={`dashboard__sidebar-sublist-item-name ${
                      item.status ? item.status.toLowerCase() : ''
                    }`}
                  >
                    {item.name}
                  </p>
                </NavLink>
              </li>
            )
          })}
        </ul>
      </div>
      <SubscriptionGuard>
        <Link to="/storage/create" style={{ textDecoration: 'none' }}>
          <button className="dashboard__sidebar-btn--create">
            <object
              className="dashboard__sidebar-btn--create-icon"
              data={plusIcon}
              type="image/svg+xml"
            >
              Plus Icon
            </object>
            <span className="dashboard__sidebar-btn--create-text">
              Create New
            </span>
          </button>
        </Link>
      </SubscriptionGuard>
    </>
  )
}

const Sidebar = () => {
  const { distributions } = useStateContext()

  return (
    <>
      <div className="dashboard__sidebar">
        <div className="dashboard__sidebar-nav">
          <ul className="dashboard__sidebar-nav-list">
            <li className="dashboard__sidebar-nav-list-item">
              <NavLink className="dashboard__sidebar-nav-list-item-link" to="/">
                <svg
                  className="dashboard__sidebar-nav-list-item-icon"
                  width="24"
                  height="22"
                  viewBox="0 0 24 22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M23.5033 10.8198L12.5988 0.240933C12.5203 0.164557 12.427 0.103965 12.3244 0.0626224C12.2217 0.0212801 12.1116 0 12.0005 0C11.8894 0 11.7793 0.0212801 11.6766 0.0626224C11.574 0.103965 11.4807 0.164557 11.4022 0.240933L0.497704 10.8198C0.180021 11.1282 0 11.5471 0 11.984C0 12.8913 0.759793 13.629 1.69431 13.629H2.84327V21.1775C2.84327 21.6325 3.22184 22 3.69042 22H10.3062V16.2428H13.2712V22H20.3106C20.7792 22 21.1577 21.6325 21.1577 21.1775V13.629H22.3067C22.7567 13.629 23.1883 13.4568 23.5059 13.1458C24.1651 12.5032 24.1651 11.4623 23.5033 10.8198V10.8198Z" />
                </svg>
                <span className="dashboard__sidebar-nav-list-item-text">
                  Home
                </span>
              </NavLink>
            </li>
            <li className="dashboard__sidebar-nav-list-item">
              <NavLink
                to="/distributions"
                className={({ isActive, isPending }) =>
                  'dashboard__sidebar-nav-list-item-link ' +
                  (isPending ? 'pending' : isActive ? 'active' : '')
                }
              >
                <svg
                  className="dashboard__sidebar-nav-list-item-icon globe"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="#505050"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2 12H22"
                    stroke="#505050"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2V2Z"
                    stroke="#505050"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="dashboard__sidebar-nav-list-item-text">
                  Distributions
                </span>
              </NavLink>
            </li>
          </ul>
          <ul className={`dashboard__sidebar-sublist`}>
            {distributions.map(item => {
              return (
                <li key={item.id} className="dashboard__sidebar-sublist-item">
                  <NavLink
                    to={`/distributions/${item.id}`}
                    className={({ isActive, isPending }) =>
                      'dashboard__sidebar-sublist-item-link ' +
                      (isPending ? 'pending' : isActive ? 'active' : '')
                    }
                  >
                    <p
                      className={`dashboard__sidebar-sublist-item-name ${
                        item.status ? item.status.toLowerCase() : ''
                      }`}
                    >
                      {item.name}
                    </p>
                  </NavLink>
                </li>
              )
            })}
          </ul>
        </div>
        <SubscriptionGuard>
          <Link to="/distributions/create" style={{ textDecoration: 'none' }}>
            <button className="dashboard__sidebar-btn--create">
              <object
                className="dashboard__sidebar-btn--create-icon"
                data={plusIcon}
                type="image/svg+xml"
              >
                Plus Icon
              </object>
              <span className="dashboard__sidebar-btn--create-text">
                Create New
              </span>
            </button>
          </Link>
        </SubscriptionGuard>
        <br />
        {process.env.REACT_APP_FEATURE_STORAGE !== 'false' && <StorageSidebar />}
      </div>
    </>
  )
}

export default Sidebar
